<template>
    <div class="navbar">
        <div class="bar-header bj-flex-center">
            <!-- <div class="header-login bj-flex-start" :class="{ 'header-lg': menuShow }"> -->
                <!-- <img class="left-img" src="@/assets/l.png" /> -->
                <!-- <img class="right-img" src="@/assets/top-logo.png" /> -->
            <!-- </div> -->
            <div class="bj-flex-center header-icon" style="cursor: pointer">
                <el-icon class="el-icon" v-if="menuShow" @click="switchTouch(false)"><Fold /></el-icon>
                <el-icon class="el-icon" v-else @click="switchTouch(true)"><Expand /></el-icon>
            </div>
            <div class="header-title bj-flex-start">
                <div>本家广告管理系统</div>
            </div>
            <div class="bj-flexitem-1" />
            <div class="user-add bj-flex-center">
                <el-dropdown @command="handleDropdown">
                    <span class="user-info bj-flex-center">
                        <img class="user-img" v-if="userInfo.avatarUrl && userInfo.avatarUrl != 'none'" :src="userInfo.avatarUrl" />
                        <img class="user-img" v-else src="@/assets/user-bg.png" />
                        {{ userInfo.nickName ? userInfo.nickName : userInfo.realName }}
                        <el-icon class="el-icon--right" size="14px" color="#000">
                            <arrow-down />
                        </el-icon>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="modifyPwd">修改密码</el-dropdown-item>
                            <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <el-dialog title="修改密码" v-model="passwordShow" width="400px">
            <el-form
                ref="ruleFormRef"
                style="width: 350px"
                :model="passwordInfo"
                :rules="rules"
                label-position="right"
                label-width="80px"
            >
                <el-form-item label="新密码" prop="password">
                    <el-input v-model="passwordInfo.password" type="password" placeholder="请输入新密码" />
                </el-form-item>
                <el-form-item label="密码确认" prop="repassword">
                    <el-input v-model="passwordInfo.repassword" type="password" placeholder="请再次输入新密码" />
                </el-form-item>
                <el-form-item style="margin-top: 20px">
                    <el-button @click="passwordShow = false">取 消</el-button>
                    <el-button type="primary" @click="submitModifyPwd(ruleFormRef)">提 交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, reactive, inject } from "vue";
import Cache from "@/utils/cache";
import { validate } from "@/utils/validate";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox, FormRules, FormInstance } from "element-plus";
import {
    MoreFilled,
    ArrowLeftBold,
    ArrowDown,
    Lock,
} from "@element-plus/icons-vue";

export default defineComponent({
    components: {
        MoreFilled,
        ArrowLeftBold,
        ArrowDown,
        Lock,
    },
    setup() {
        const reload: any = inject("reload");
        const handleExit = () => {
            localStorage.removeItem("token");
        };
        const store = useStore();
        const userIndentity = computed(() => Cache.getStorage(Cache.keyUserList) || store.getters.adminUserList);
        const device = computed(() => store.state.app.device);
        const ruleFormRef = ref<FormInstance>();
        let userInfo = computed(() => store.getters.advertAdminInfo || Cache.getStorage(Cache.advertAdminUser)||{});
        const menuShow = computed(() => store.state.permissionRepast.adminMenuShape);
        let passwordShow = ref<any>(false);
        // 修改密码参数
        let passwordInfo = ref<any>({
            password: "",
            repassword: "",
        });
        //表单验证
        const rules = reactive<FormRules>({
            password: [
                {
                    required: true,
                    message: "请输入密码",
                    trigger: "blur",
                },
                {
                    min: 4,
                    max: 16,
                    message: "请输入6-20位数字和字母组成",
                    trigger: "blur",
                },
            ],
            repassword: [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value !== passwordInfo.value.password) {
                            callback(new Error("两次输入密码不一致!"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
        });
        //退出登录
        let handleDropdown = (item) => {
            if (item == "logout") {
                ElMessageBox.confirm("确定要退出登录", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
					store.dispatch("userAdvert/logout");
				}).catch(() => {});
            } else {
                passwordInfo.value = {
                    password: "",
                    repassword: "",
                };
                passwordShow.value = true;
            }
        };
        const global: any = inject("global");
        //修改密码
        let submitModifyPwd = async (formEl: FormInstance | undefined) => {
            if (!formEl) return;
            if (!validate.IsPassword(passwordInfo.value.password)) {
                // 4到16位（字母，数字，下划线，减号）
                ElMessage({
                    message: "请输入6到20位数字和字母组成的密码",
                    type: "warning",
                });
                return;
            }
            await formEl.validate((valid, fields) => {
                if (valid) {
                    global.https.post("/passport/modifyPwd", {
						password: passwordInfo.value.password,
					}).then((res: any) => {
						if (res != null && res.code == "44444") {
							ElMessage.error(res.message);
							return;
						}
						ElMessage({
							message: "修改成功",
							type: "success",
						});
						passwordShow.value = false;
						store.dispatch("userAdvert/logout");
					});
                }
            });
        };
        const switchTouch = (item) => {
            store.dispatch("permissionRepast/showAdd", item);
        };
        return {
            handleExit,
            device,
            userInfo,
            menuShow,
            userIndentity,
            passwordShow,
            passwordInfo,
            rules,
            ruleFormRef,
            handleDropdown,
            submitModifyPwd,
            switchTouch,
        };
    },
});
</script>

<style lang="scss" scoped>
.navbar {
    overflow: hidden;
    width: 100%;
    color: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    background: #fff;
    .bar-header {
        height: 64px;
        .header-login {
            width: 200px;
            height: 100%;
            background: #324157;
            border-bottom: 1px solid #1182fa;
        }
        .header-lg {
            width: 64px;
        }
    }
    .header-title {
        padding: 0px 10px;
        color: #2d2e2f;
        font-size: 22px;
        font-weight: 550;
    }
    .company-type{
        font-size: 20px;
        font-weight: 500;
    }
    .header-icon {
        margin-left: 6px;
        .el-icon {
            font-size: 26px;
            color: #000;
        }
    }
}
.user-add {
    padding: 0px 30px 0px 20px;
    position: relative;
    cursor: pointer;
}
::v-deep .el-dropdown-menu {
    border-radius: 0 !important;
}
.user-info {
    color: #000;
    font-size: 16px;
    .user-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
.left-img {
    width: 32px;
    height: 32px;
    margin: 0 16px 0 16px;
}
.right-img {
    width: 85px;
    height: 16px;
}
</style>
