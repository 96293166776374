<template>
    <div class="tags-view">
        <scroll-pane class="tags-view-wrapper" ref="scrollPane" :visitedViews="visitedViews">
            <div class="tags-view-item">
                <div @click="navTouch('/')" class="tags-item bj-flex-center" :class="isActive({ path: '/' }) ? 'active' : ''">
                    首页
                </div>
            </div>
            <div class="tags-view-item" @click="navbarTouch(tag)" ref="tagsItem" v-for="tag in Array.from(visitedViews)" :key="tag.path">
                <div class="tags-item bj-flex-center" :class="isActive(tag) ? 'active' : ''">
                    {{ tag.name }}
                    <el-icon class="tags-icon" @click.prevent.stop="closeSelectedTag(tag)">
						<CloseBold />
					</el-icon>
                </div>
            </div>
        </scroll-pane>
        <div class="right-btn" @click.stop="changeLabel">
            <el-icon><ArrowDown /></el-icon>
        </div>
        <ul class="contextmenu" v-show="visible">
            <li @click="closeSelectedTag(selectedTag.value)">关闭当前页</li>
            <li @click="closeOthers">关闭其他页</li>
            <li @click="closeAll" >
                关闭全部
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import {
    defineComponent,
    computed,
    ref,
    reactive,
    watch,
    nextTick,
    onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import scrollPane from "./ScrollPane.vue";
import { CloseBold, ArrowDown } from "@element-plus/icons-vue";
import Cache from "@/utils/cache";

export default defineComponent({
    components: {
        scrollPane,
        CloseBold,
        ArrowDown,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        //导航快捷列表
        const visitedViews = computed(() => store.state.tagsView.visitedViews);
        const items = computed(() => store.state.permissionRepast.adminMenuList);
        let userInfo = computed(() => store.getters.advertAdminInfo || Cache.getStorage(Cache.advertAdminUser));
        const route = useRoute();
        let selectedTag = reactive<any>({});
        const isActive = (item) => {
            selectedTag.value = item;
            return item.path === route.path;
        };
        //快捷列表单个删除事件
        const closeSelectedTag = (item) => {
            store.dispatch("delVisitedViews", item).then((views) => {
                if (isActive(item)) {
                    const latestView = views.slice(-1)[0];
                    if (latestView) {
                        router.push(latestView.path);
                    } else {
                        router.push("/");
                    }
                }
            });
        };
        //关闭标签
        let visible = ref<any>(false);
        watch(visible, (newValue) => {
            if (newValue) {
                document.body.addEventListener("click", closeLabel);
            } else {
                document.body.removeEventListener("click", closeLabel);
            }
        });
        //操作标签
        const changeLabel = () => {
            visible.value = !visible.value;
        };
        const closeLabel = () => {
            visible.value = false;
        };
        //关闭其他页
        const closeOthers = () => {
            store.dispatch("delOthersViews", selectedTag).then((item) => {});
        };
        //关闭全部
        const closeAll = () => {
            store.dispatch("delAllViews");
            store.dispatch("permissionRepast/levelDevice", {});
            router.push("/");
        };
        const navTouch = (item) => {
            store.dispatch("permissionRepast/levelDevice", {});
            router.push(item);
        };
        const navbarTouch = (e) => {
            items.value.forEach((item) => {
                if (item.path == e.path) {
                    store.dispatch("permissionRepast/levelDevice", item);
                    return;
                } else {
                    if (item.children && item.children.length > 0) {
                        item.children.forEach((e2) => {
                            if (e2.path == e.path) {
                                store.dispatch("permissionRepast/levelDevice", item);
                                return;
                            } else {
                                if (e2.children && e2.children.length > 0) {
                                    e2.children.forEach((e3) => {
                                        if (e3.path == e.path) {
                                            store.dispatch("permissionRepast/levelDevice", item);
                                            return;
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });
            router.push(e.path);
        };
        onMounted(() => {});
        return {
            visitedViews,
            isActive,
            closeSelectedTag,
            closeOthers,
            closeAll,
            visible,
            changeLabel,
            selectedTag,
            navTouch,
            navbarTouch,
            userInfo,
        };
    },
});
</script>

<style lang="scss" scoped>
.tags-view {
    border-top: 1px solid #d8dce5;
    position: relative;
    padding-right: 30px;
}
.tags-view-wrapper {
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

    .tags-view-item {
        display: inline-block;
        position: relative;
        color: #979899;
        background: #fff;
        font-size: 12px;
        margin-left: 5px;
        cursor: pointer;
        &:first-of-type {
            margin-left: 15px;
        }
        .tags-item {
            height: 30px;
            padding: 0 8px;
            background: #eeeff0;
        }
        .active {
            padding: 0 8px 0 16px;
            background-color: #2c2830;
            position: relative;
            color: #fff;
            &::before {
                content: " ";
                background: #fff;
                display: inline-block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                margin-right: 5px;
                left: 6px;
            }
        }
        .tags-icon {
            margin-left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            &:hover {
                background-color: #b4bccc;
                color: #fff;
            }
        }
    }
}
.right-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 10px;
    cursor: pointer;
    height: 100%;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    &:hover {
        background: #eee;
    }
}
.contextmenu {
    right: 0;
    margin: 0;
    background: #fff;
    z-index: 100;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
        margin: 0;
        padding: 7px 16px;
        cursor: pointer;
        &:hover {
            background: #eee;
        }
    }
}
</style>