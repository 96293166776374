import https from '../../utils/http'
import Cache from "@/utils/cache";
import store from "@/store";

const state: any = {
	advertAdminInfo: null,
	advertAdminToken: '',
}

const mutations = {
	//set token
	SET_TOKEN: (state, advertAdminToken) => {
		state.advertAdminToken = advertAdminToken
	},
	//clear token
	CLEAR_TOKEN: (state) => {
		state.advertAdminToken = ''
	},
	SET_USER_INFO: (state, advertAdminInfo) => {
		state.advertAdminInfo = advertAdminInfo
	},
	CLEAR_USER_INFO: (state) => {
		state.advertAdminInfo = null
	}
}

const actions = {
	// user logout
	logout({ commit }, state: any) {
		return new Promise((resolve, reject) => {
			https.post("/passport/logout", {}, false).then((res: any) => {
				store.dispatch('permissionRepast/menuDel');
				store.dispatch('permissionRepast/clearUserList');
				Cache.setStorage(Cache.advertAdminUser, null);
				Cache.setToken(Cache.advertAdminToken, null, null);
				Cache.setStorage(Cache.keyUserList, null)
				commit('CLEAR_TOKEN')
				commit('CLEAR_USER_INFO')
				resolve('ok')
				window.location.href = `${location.origin}/login.html`
			}).catch((err: any) => {
				reject(err)
			});
		})
	},
	// 通用跳转至登录页
	toLogin({ commit }, state: any) {
		window.location.href = `${location.origin}/login.html`;
	},
	// 通用跳转至登录页
	toJoin({ commit }, merchantId: any) {
		window.location.href = `${location.origin}/join.html`;
	},
	// 通用跳转至首页
	toHome({ commit }, state: any) {
		window.location.href = `${location.origin}/`;
	},
	// 获取用户信息
	getInfo({ commit, state }) {
		return new Promise((resolve, reject) => {
			https.get("/passport/getInfo", {}, false, null).then((res: any) => {
				Cache.setStorage(Cache.advertAdminUser, res);
				commit('SET_USER_INFO', res)
				resolve(res)
			}).catch((err: any) => {
				reject(err)
			});
		})
	},
	setToken({ commit }, token: string) {
		commit('SET_TOKEN', token)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
