<template>
    <div class="left-nav-box" :style="menuShow?'width: 232px;':'width: 64px;'">
        <div class="header-box">
            <div class="header-name bj-flex-start" v-if="menuShow">
                <img class="logo-class" src="../../assets/menu/logo.png"/>广告后台系统
            </div>
            <div class="header-name" v-else><img class="logo-class" src="../../assets/menu/logo.png"/></div>
        </div>
        <div class="func-page-tab">
            <el-scrollbar wrap-class="scrollbar-wrapper">
                <el-menu
                    :default-active="nowPath"
                    background-color="#2c2830"
                    text-color="#747276"
                    active-text-color="#fff"
                    :collapse="!menuShow"
                    :unique-opened="true"
                    :collapse-transition="false"
                >
                    <template v-for="item in adminMenuList" :key="item.path">
                        <el-sub-menu
                            :index="item.path"
                            v-if="item.children && item.children.length > 0"
                        >
                            <template #title>
                                <div class="sidebar-icon" v-if="item.icon">
                                    <el-image :src="item.icon"></el-image>
                                </div>
                                <span>{{ item.name }}</span>
                            </template>
                            <template
                                v-for="subItem in item.children"
                                :key="subItem.path"
                            >
                                <el-menu-item :index="subItem.path"  @click="sidebarTouch(subItem)">
                                    <template #title>
                                        <span>{{ subItem.name }}</span>
                                    </template>
                                </el-menu-item>
                            </template>
                        </el-sub-menu>
                        <el-menu-item
                            v-else
                            :index="item.path"
                            @click="sidebarTouch(item)"
                        >
                        <div class="sidebar-icon" v-if="item.icon">
                            <el-image :src="item.icon"></el-image>
                        </div>
                        <span>{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </el-scrollbar>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted,computed } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
    name: "leftNav",
    components: {},
    setup() {
        const route = useRoute();
        const nowPath = computed(() => {
            return route.path;
        });
        const store = useStore();
        const router = useRouter();
        let adminMenuList = computed(() => store.getters.adminMenuList);
        const menuShow = computed(() => store.state.permissionRepast.adminMenuShape);
        const sidebarTouch = (item: any) => {
            store.dispatch("addVisitedViews", item);
            store.dispatch("permissionRepast/levelDevice", item);
            router.push(item.path)
        };
        return {
            nowPath,
            adminMenuList,
            sidebarTouch,
            menuShow,
        };
    },
});
</script>
<style lang="scss" scoped>
.left-nav-box {
    background: #2c2830;
    height: 100%;

    .header-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 12px;
        height: 70px;
        .header-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-size: 30px;
            margin-right: 12px;

            background-color: #fff;
        }

        .header-name {
            color: #fff;
            font-size: 18px;
        }
    }
}

.func-page-tab {
    > * {
        font-size: 14px !important;
    }

    .el-menu {
        border-right: unset;
    }

    .el-menu-item {
        height: 36px;
        line-height: 36px;
    }

    .el-menu-item-group {
        .is-active {
            background: #7c75da !important;
        }
    }

    ::v-deep .is-active {
        .el-submenu__title {
            color: #fff !important;
        }
    }

    ::v-deep .el-submenu__title {
        height: 50px;
        line-height: 50px;
    }

    ::v-deep .el-menu-item-group__title {
        padding: 0;
    }
}

.service-qr-code {
    position: absolute;
    bottom: 15%;
    left: 50px;

    width: 132px;
    text-align: center;

    .qr-code {
        width: 132px;
        height: 132px;
        background-size: cover;
        margin: auto;
        border-radius: 5px;
    }

    > div:first-child {
        width: 122px;
        height: 122px;
        background-color: #fff;
        border-radius: 5px;
    }

    > div:last-child {
        font-size: 12px;
        color: #fff;
        margin-top: 20px;
    }
}
::v-deep .el-sub-menu__title *{
    vertical-align:middle !important;
    display: inline-block;
}
::v-deep .el-image{
    display: block;
    width: 20px;
    margin-right: 10px;
}
.logo-class{
    width: 32px; 
    height: 32px;
    margin-right: 12px;
}

</style>